<template>
  <metainfo>
    <template v-slot:title="{ content }">{{
      content ? `${content} | Spartan Energy` : `Spartan Energy`
    }}</template>
  </metainfo>
  <div v-if="!this.isLoading" class="news-page">
    <Page :hasSplash="false">
      <template #pageContent>
        <Panel header="Coming Soon" :width="60" />
      </template>
    </Page>
  </div>
</template>

<script lang="ts">
import "./news-page.scss";
import { Options, setup, Vue } from "vue-class-component";
import { useMeta } from "vue-meta";
import Page from "@/components/page/page.vue";
import Panel from "@/components/panel/panel.vue";

@Options({
  name: "NewsPage",
  components: {
    Page,
    Panel,
  },
})
export default class NewsPage extends Vue {
  //#region Meta
  meta = setup(() =>
    useMeta({
      title: "News & Updates",
      htmlAttrs: { lang: "en", amp: true },
    })
  );
  //#endregion

  //#region Component
  isLoading = true;
  //#endregion

  //#region Hooks
  created(): void {
    this.setup();
  }

  async setup(): Promise<void> {
    this.isLoading = false;
  }
  //#endregion
}
NewsPage.registerHooks([
  "beforeRouteEnter",
  "beforeRouteUpdate",
  "beforeRouteLeave",
]);
</script>
